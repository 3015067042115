@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");

/* general */
html {
  overflow-x: hidden !important;
}

body {
  width: 100%- 10px;
  overflow-x: hidden !important;
  font-family: "Roboto", sans-serif;
}

.slick-slide div {
  outline: none;
}

/*::-webkit-scrollbar {
  height: 0px !important;
  width: 0px !important;  Remove scrollbar space 
  background: transparent;  Optional: just make scrollbar invisible 
}*/
/* width */

@media only screen and (min-width: 768px) {
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.th-rob {
  font-family: "Roboto", sans-serif;
}

.th-roboto {
  font-family: "Roboto", sans-serif;
}

@media screen and (max-width: 767px) {
  .th-6 {
    font-size: 6px;
  }

  .th-8 {
    font-size: 8px;
  }

  .th-10 {
    font-size: 10px;
  }

  .th-12 {
    font-size: 12px;
  }

  .th-13 {
    font-size: 12px;
  }

  .th-14 {
    font-size: 12px;
  }

  .th-16 {
    font-size: 14px;
  }

  .th-18 {
    font-size: 14px;
  }

  .th-20 {
    font-size: 16px;
  }

  .th-22 {
    font-size: 16px;
  }

  .th-24 {
    font-size: 18px;
  }

  .th-30 {
    font-size: 20px;
  }

  .th-36 {
    font-size: 22px;
  }

  .th-72 {
    font-size: 24px;
  }
}

@media screen and (min-width: 768px) {
  .th-6 {
    font-size: 6px;
  }

  .th-8 {
    font-size: 8px;
  }

  .th-10 {
    font-size: 10px;
  }

  .th-12 {
    font-size: 12px;
  }

  .th-13 {
    font-size: 13px;
  }

  .th-14 {
    font-size: 14px;
  }

  .th-16 {
    font-size: 16px;
  }

  .th-18 {
    font-size: 18px;
  }

  .th-20 {
    font-size: 20px;
  }

  .th-22 {
    font-size: 22px;
  }

  .th-24 {
    font-size: 24px;
  }

  .th-30 {
    font-size: 30px;
  }

  .th-36 {
    font-size: 36px;
  }

  .th-72 {
    font-size: 72px;
  }
}

/* font weight */
.th-thin {
  font-weight: 100;
}

.th-light {
  font-weight: 300;
}

.th-regular {
  font-weight: 400;
}

.th-medium {
  font-weight: 500;
}

.th-bold {
  font-weight: 700;
}

/* font color */
.th-black {
  color: #000000;
}

.th-grey {
  color: #959595;
}

.th-dark-grey {
  color: #575a62;
}

.th-white {
  color: #ffffff;
}

.th-green {
  color: #51aa1b;
}

.th-color-1 {
  color: #fb8b24;
}

.th-color-2 {
  color: #a77e6b;
}

.th-color-3 {
  color: #9a031e;
}

.th-red {
  color: #9a031e;
}

.th-red-2 {
  color: #be3319;
}

.th-red-dark {
  color: #53130c;
}

.th-yellow {
  color: #ffc24a;
}

/* Link */

.th-link:hover {
  text-decoration: none;
}

.th-link:active {
  text-decoration: none;
}

.th-link-color {
  text-decoration: underline;
  color: #0780b4;
}

.th-link-color-1 {
  text-decoration: none;
  color: #fb8b24;
}
.th-link-color-1:hover {
  text-decoration: none;
  color: #e07a1a;
}

/* BUTTON */
.th-btn-white {
  font-weight: 500;
  color: #000000;
  background: #ffffff;
  border-radius: 50px;
  padding: 8px 24px 8px 24px;
  border: 1px solid #ffffff;
}

.th-btn-white-ghost {
  font-weight: 500;
  color: #ffffff;
  background: none;
  border: none;
}

.th-btn-white-light {
  color: #ffffff;
  background: rgba(255, 254, 254, 0.19);
  border-radius: 5px;
  border: none;
}

.th-btn-white-outline {
  font-weight: 500;
  color: #000000;
  background: #ffffff;
  border-radius: 50px;
  padding: 8px 24px 8px 24px;
  border: 1px solid #979797;
}

.th-btn-color-1 {
  font-weight: 500;
  color: #ffffff;
  background: #fb8b24;
  border-radius: 50px;
  padding: 8px 10px 8px 10px;
  border: 1px solid #fb8b24;
}

.th-btn-white-outline:hover {
  color: #000000;
}

.th-btn-white:focus,
.th-btn-white-ghost:focus,
.th-btn-white-light:focus,
.th-btn-white-outline:focus,
.th-btn-color-1:focus,
.th-btn-gradient:focus {
  outline: none;
}

/* BG Color */
.th-bg-white {
  background: #ffffff;
}

.th-bg-grey {
  background: #dcddde;
}

.th-bg-light-grey {
  background: #f5f5f5;
}

.th-bg-dark-grey {
  background: #ececec;
}

.th-bg-dark-red {
  background: #9a031e;
}

.th-bg-color-1 {
  background: #fb8b24;
}

.th-bg-color-4 {
  background: hsla(0, 67%, 21%, 0.35);
}
.th-bg-color-4:hover {
  background-color: #fff;
  color: #53130c;
}

.bg-white .th-bg-color-4:hover {
  background-color: #fb8b24 !important;
  color: #fff;
}

.th-bg-gradient-1 {
  background: linear-gradient(104.11deg, #f8a457 1.18%, #e78a00 98.15%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 9px;
}

/* Border Radius */
.th-border-2 {
  border-radius: 2px;
}

.th-border-10 {
  border-radius: 10px;
}

.th-border-top-10 {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.th-border-bottom-10 {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.th-border-color-1 {
  border: 2px solid #fb8b24;
}

@media screen and (max-width: 767px) {
  .th-hidden-sm {
    display: none !important;
  }
}

@media screen and (min-width: 768px) {
  .th-hidden-md {
    display: none !important;
  }
}

.th-side-drawer-backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1500;
  top: 0;
  right: 0;
}

.th-side-drawer {
  height: 100%;
  background: white;
  position: fixed;
  top: 0;
  right: 0;
  width: 65%;
  z-index: 2000;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  transform: translateX(100%);
  transition: transform 0.3s ease-out;
}

.th-side-drawer.open {
  transform: translateX(0);
}

.th-footer-background {
  background: #272727;
}

.th-footer-image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 26px;
  width: 45px;
}

/* patch red */
.th-patch-short::before {
  content: "";
  width: 8px;
  height: 30px;
  position: absolute;
  left: 15px;
  z-index: 1;
  background: #9a031e;
}

.th-patch-long::before {
  content: "";
  width: 8px;
  height: 50px;
  position: absolute;
  left: 15px;
  z-index: 1;
  background: #9a031e;
}

.th-patch-ex-long::before {
  content: "";
  width: 8px;
  height: 70px;
  position: absolute;
  left: 15px;
  z-index: 1;
  background: #9a031e;
}

a {
  color: inherit;
  text-decoration: none;
  outline: none;
}

a:hover {
  text-decoration: none;
  color: inherit;
}

.th-italic {
  font-style: italic;
}

.th-footer-input {
  border: none;
  outline: none;
  background: none;
  width: 100%;
}

.th-strike {
  text-decoration: line-through;
}

/* breadcrumb */
.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  color: #6c757d;
  content: "> ";
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #ffffff00;
  border-radius: 0.25rem;
  /* color: #9A031E; */
}

.breadcrumb-item a {
  color: #9a031e;
  font-weight: 500;
  font-size: 14px;
}

.breadcrumb-item.active {
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  color: #ffffff;
  content: "> ";
}

.breadcrumb-row {
  margin-top: -4%;
  position: absolute;
  align-items: baseline;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .breadcrumb-row {
    margin-top: -10%;
  }
}

/* gradient button */
.th-btn-gradient {
  background: linear-gradient(90.45deg, #e36414 -1.52%, #9a031e 100%);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  color: white;
  width: 100%;
  outline: none;
  border: none;
}

.th-btn-gradient:hover,
.th-btn-gradient:active {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);
  color: white;
}

.th-btn-gradient-yellow {
  background: #fb8b24;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.18231);
  border-radius: 19px;
  color: white;
}

.form-control::-webkit-input-placeholder {
  color: #d1d1d1;
}

/* WebKit, Blink, Edge */
.form-control:-moz-placeholder {
  color: #d1d1d1;
}

/* Mozilla Firefox 4 to 18 */
.form-control::-moz-placeholder {
  color: #d1d1d1;
}

/* Mozilla Firefox 19+ */
.form-control:-ms-input-placeholder {
  color: #d1d1d1;
}

/* Internet Explorer 10-11 */
.form-control::-ms-input-placeholder {
  color: #d1d1d1;
}

/* Microsoft Edge */

input[type="date"]::before {
  color: black;
  content: attr(placeholder) ": ";
}

/* input[type="date"]:focus::before {
  content: "" !important;
} */

.th-plus-box {
  background-color: rgba(0, 0, 0, 0.24);
  border: 0.5px dashed rgba(255, 255, 255, 0.72);
  box-sizing: border-box;
  border-radius: 7px;
}

.th-plus-box-transparent {
  /* background-color: rgba(0, 0, 0, 0.24); */
  border: 0.5px dashed grey;
  box-sizing: border-box;
  border-radius: 7px;
  height: 90px;
}

.custom-select:focus {
  border: 1px solid #ced4da;
  outline: 0;
  box-shadow: none;
}

.cursor {
  cursor: pointer;
}
